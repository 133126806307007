h2 {
  text-transform: uppercase;
}

// Skills
.skills-container {
  background-color: $blue-gray;

  .skills-wrapper {
    @include constrain;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 35px;

    @media all and(max-width: $md){
      flex-direction: column;

      >div:nth-child(1) {
        margin-bottom: 60px;
      }
    }

    @media all and(min-width: $md){
      padding-top: 100px;
    }
  }
}

.experience,
.skills {
  text-align: center;
  flex-basis: 45%;

  .title {
    display: inline-block;
    text-align: center;
    color: $text;
    font-weight: 600;
    font-size: 20px;

    &::after {
      content: '';
      display: block;
      width: inherit;
      max-width: 200px;
      height: 2px;
      margin: 12px auto;
      background-color: $purple-light;
    }
  }

  .content {

    margin: {
      top: 20px;
      left: auto;
      right: auto;
    }
    color: $text;
    text-align: center;

    p {
      line-height: 1.3em;
      max-width: 500px;
      margin: auto;
      font: {
        size: 16px;
        weight: 200;
      };
    }
  }
  .sub-hero-btn {
    //@include button($purple-light);
    @include button-mask($purple-light, white);
    border-radius: 42px;
    font-weight: 600;
    font-size: 15px;
    color: $text;

    &::before,
    &::after {
      border: 2px solid $purple-light;
    }
  }
}
.tech-stack {
  padding-top: 50px;

  h2 {
    text-align: center;
    font: {
      weight: 600;
      size: 32px;
    };
    color: $text;
    margin: 15px 0;
  }

  .tech-images {
    .tech-line {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 60px 0;

      >div {
        max-width: 75px;
        padding: 2px;
      }
    }

    img {
      max-width: 100%;
      //max-width: 75px;
      align-self: center;
      height: auto;
    }
  }
  .tech-wrapper {
    @include constrain;
    margin: auto;
  }
}
.clients {
  background-image: $black-gradient;
  padding: 65px 20px;

  .clients-wrapper {
    @include constrain;
    margin: auto;
  }

  h2 {
    margin: {
      bottom: 50px;
    }
    color: white;
    font: {
      size: 32px;
    };
    text-align: center;
  }

  .clients-logos {
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;

    >div {
      flex-shrink: 1;
      padding: 0 4px;

      img {
        max-width: 100%;
      }
    }

    @media all and (max-width: $sm){
      flex-direction: column;
      align-items: center;
    }
  }
}

.team {
  padding: 60px 0 50px;
  background-color: $blue-gray;

  h2 {
    font: {
      size: 32px;
      weight: 600;
    };
    margin: {
      bottom: 40px;
    };
    color: $text;
    text-align: center;
  }

  p {
    line-height: 1.2em;
  }

  .team-wrapper {
    @include constrain;
    display: flex;
    margin: auto;

    .team-text {
      line-height: 1.3em;
      font: {
        size: 16px;
        weight: 200;
      };
    }

    .find-out-more {
      margin: {
        top: 10px;
      };
      font: {
        weight: 900;
        size: 16px;
      };
      color: $purple-light;
    }

    .contact-action {
      //@include button($purple-light);
      font-size: 14px;

      &-link {
        @include button-mask($purple-light, white);
        height: 45px;
        border-radius: 42px;
        font-weight: 600;
        font-size: 15px;
        color: $text;

        &::before,
        &::after {
          border: 2px solid $purple-light;
        }
      }
    }

    @media screen and (min-width: $md) {

      .team-text-section{
        flex-basis: calc(100% - 750px);
        padding: {
          right: 15px;
        };
      }

      .team-images {
        flex-basis: 750px;
      }
    }
    @media screen and (max-width: $md) {
      flex-direction: column;

      .team-text-section {
        text-align: center;
        margin: 20px auto;
      }
    }

    .team-images {
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start;
      justify-content: flex-start;

      .team-member {
        position: relative;
        overflow: hidden;
        @media all and (min-width: $md){
          max-width: 150px;
          max-height: 150px;
          flex: {
            basis: 150px;
            shrink: 0;
          }
          .card {
            top: 150px;
            height: 150px;
            .card-wrapper {
              margin: {
                top: 50%;
              };
              transform: translateY(-50%);
            }
          }
          &:hover {
            .card {
              top: 0;
            }
          }
        }
        @media all and (max-width: $md){
          flex: {
            basis: calc(100% / 3);
          };
          .card {
            bottom: 0;
          }
        }
        @media all and (max-width: $sm){
          flex: {
            basis: 50%;
          };
        }
        @media all and (max-width: $xs){
          flex: {
            basis:  100%;
          };
        }
        .card {
          position: absolute;
          background: rgba(68, 68, 68, 0.8);
          left: 0;
          right: 0;
          color: white;
          transition: all 0.5s ease-in-out;
          padding: 5px;
          text-align: center;

          .name,
          .position {
            margin: 5px 0;
          }
          .name {
            font: {
              size: 14px;
              weight: 600;
            };

            > span:first-of-type {
              padding-right: 1px;
            }

          }
          .position {
            font: {
              size: 12px;
              weight: 200;
            };
          }
        }
        img {
          display: block;
          max-width: 100%;
          height: auto;
        }

      }
    }
  }
}
.medium {
  padding: 70px 10px 60px 10px;
  box-sizing: border-box;

  figcaption {
    display: none;
  }
  a {
    text-decoration: none;
    color: $text;
  }
  >h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 60px;
    color: $text;
    text-align: center;
  }

  .medium-wrapper {
    @include constrain;
    margin: auto;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    @media all and (min-width: $sm) {
      flex-direction: row;
    }
    //@media all and (max-width: $sm) {
    //  display: block;
    //}

    .image-wrapper {
      position: relative;
    }

    .article-link {
      max-width: 350px;
      align-self: center;
    }
    .article {
      &:hover {
        //box-shadow: 0px -9px 37px 2px rgba(0,0,0,0.18);
        h3 {
          text-decoration: underline;
        }
      }
      @media all and (max-width: $ls) {
       //padding: 1em;
        margin: auto;
      }
      @media all and (min-width: $ls) and (max-width: $sm) {
        //max-width: 100%;
        //padding: 1em 4em ;
      }
      img {
        max-width: 100%;
      }
      .teaser-image {
        //max-height: 150px;
        overflow: hidden;
        @media all and (min-width: $ls) and (max-width: $sm) {
        //max-height: 200px;
        }
      }
      h3 {
        text-transform: capitalize;
        padding: 0 5px;
        font: {
          size: 18px;
          weight: 900;
        }
        margin: 15px 0 10px 0;
        color: $purple-light;
        text-align: center;
        line-height: 20px;
        overflow: hidden;
      }
      img {
        max-width: 100%;
        height: auto;
        @media all and (min-width: $ls) and (max-width: $sm) {
          height: auto;
          margin: auto;
        }
      }
      p {
        text-align: left;
      }
    }

    .article-link {
      margin: 10px 5px;
      .read-more {
        padding: {
          bottom: 15px;
          right: 5px;
          left: 5px;
        }
        text-align: center;
        font: {
          size: 16px;
        };
        line-height: 1.3;
        color: $text;
      }
    }
  }
}
