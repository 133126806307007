// Region
header.region--header {
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &,
  &.fixed {
    background-color: $purple;
  }

  @media all and (min-width: $md) {
    background-color: $transparent;
  }
}

// Content wrapper.
.header--content {
  @include constrain;
  position: relative;
  left: 0;
  right: 0;
  display: flex;
  margin: auto;
  padding: 10px 15px;
  justify-content: space-between;
  //overflow: hidden;

  @media all and (min-width: $xl + 30px) {
    padding: 10px 0;
  }
}

// Components
.logo {
  display: block;
  img {
    max-width: 50px;
    transition: all 0.5s ease-in-out;
    @media all and(min-width: $md) {
      max-width: 100px;
      .fixed & {
        max-width: 50px;
      }
    }
  }
}

// Navigation component
.nav {
  display: flex;
  z-index: 10;

  // Hamburger menu icon.
  &__toggle {
    display: block;
    cursor: pointer;

    @media all and (min-width: $md) {
      display: none;

      .fixed & {
        display: none;
        align-self: center;
      }
    }

    .line{
      display: block;
      width: 20px;
      height: 3px;
      margin: 4px auto;
      background-color: #ecf0f1;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    &:hover{
      cursor: pointer;
    }

    &.is-active {
      .line {
        &:nth-child(1){
          -webkit-transform: translateY(7px) rotate(45deg);
          -ms-transform: translateY(7px) rotate(45deg);
          -o-transform: translateY(7px) rotate(45deg);
          transform: translateY(7px) rotate(45deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:nth-child(3){
          -webkit-transform: translateY(-7px) rotate(-45deg);
          -ms-transform: translateY(-7px) rotate(-45deg);
          -o-transform: translateY(-7px) rotate(-45deg);
          transform: translateY(-7px) rotate(-45deg);
        }
      }
    }
  }


  &__menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;

    .expanded & {
      right: 0;
      opacity: 1;
      animation: slide--left 400ms cubic-bezier(0.77,0.2,0.05,1.0);
      -webkit-backface-visibility: hidden;

      _:-ms-lang(x), & {
        animation: slide--down-ie 400ms linear;
      }
    }

    @media all and(max-width: $md - 1px) {
      position: absolute;
      top: 100%;
      right: -100%;
      width: 100%;
      text-align: center;
    }

    @media all and(min-width: $md) {
      opacity: 1;
      flex-direction: row;

      .fixed & {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }

    a,
    .menulink{
      flex: 1 0 auto;
      display: block;
      width: 100%;
      margin: 0 10px;
      letter-spacing: 1px;
      color: white;
      text-decoration: none;
      font-size: 24px;
      font-weight: 200;
      line-height: 1.6;
      border: 1px solid $transparent;

      &:hover {
        cursor: pointer;
        color: $dark-salmon;
      }

      &:first-child { border-top-color: rgba(255,255,255, 0.3);}

      &:not(:last-child) { border-bottom-color: rgba(255,255,255, 0.3);}

      @media all and(min-width: $md) {
        flex-grow: 0;
        width: auto;
        margin: 0 5px;
        font-size: 14px;

        border-color: $transparent !important;
      }
    }
  }

}
