@import "variables";
@import "helpers/mixins";
@import "helpers/functions";
@import "helpers/svg";
@import "helpers/animations";
@import "helpers/masked";
@import "helpers/buttons";
@import "helpers/effects";
@import "header";
@import "hero";
@import "content-sections";
@import "process";
@import "footer";
@import "map";
@import "misc";
@import "modals";

html {
  height: 100%;
  box-sizing: border-box;
}

*, ::before, ::after {
  box-sizing: inherit;
}

img {
  display: block;
  width: 100%;
  height: auto;
}
//Reset CSS
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}

body {
  font-size: 14px;
  //font-family: $base-font-family;
  line-height: 1;
  //color: #111;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/** Typography settings **/
h1 {
  font: {
    size: 28px;
  };
}
h2 {
  font: {
    size: 20px;
  };
}

/** Sticky footer */
.page-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  header,
  section,
  footer {
    flex-shrink: 0;
  }

  main {
    flex: 1 0 auto;
  }

  a,
  .menulink{
    transition: color .3s ease-in-out;
  }
}
