@keyframes slide--down {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
}

// IE/Edge doesn't support 3d transforms.
@keyframes slide--down-ie {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide--left {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
}

// IE/Edge doesn't support 3d transforms.
@keyframes slide--left-ie {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade--in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shining {
  0% {
    left: -100%;
    transform: skewX(-30deg);
  }

  50% {
    left: 150%;
    transform: skewX(-30deg);
  }

  100% {
    left: 150%;
    transform: skewX(-30deg);
  }
}