.modal {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  opacity: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.85);
  transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  @media all and (min-width: $sm) {
    overflow-y: unset;
  }

  &.is-active {
    opacity: 100;
    height: 100vh;

    &.menu-active {
      display: block;

      @media all and (min-width: $md) {
        display: none;
      }
    }

    &.contact-active {
      display: block;
      z-index: 11;

      .contact {
        display: inherit;
      }
    }
  }
}


// Contact form
.contact {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 560px;//750px;
  transform: translate(-50%, 0);
  padding: 15px 20px 10px;
  background: no-repeat $hero-gradient center center/100%;
  background: no-repeat $gradient center center/100%;

  @media all and (min-width: $sm) and (orientation: landscape) {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 65px 50px 30px;
    border-radius: 7px;
  }

  &__title,
  &__intro {
    margin: 0 auto 15px;
    color: white;
  }
  &__title {
    font-size: 20px;

    @media all and (min-width: $sm) {
      font-size: 40px;
    }
  }

  &__intro {
    font-size: 15px;

    @media all and (min-width: $sm) {
      font-size: 20px;
    }
  }

  &__close {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 40px;
    height: 40px;
    border: 0;
    font-size: 0;
    background-color: $purple;
    transition: background-color .5s ease-in-out;

    @media all and (min-width: $sm) {
      top: 10px;
      right: 15px;
    }

    &:hover {
      background-color: $purple-light;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background: no-repeat close(white) center center/30px;
    }
  }

  &__form {
    width: 100%;
    margin: 10px auto;
  }

  &__elem {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    line-height: 2;
    font-weight: 400;
    color: white;

    input,
    textarea,
    button {
      display: block;
      width: inherit;
      padding: 10px 15px;
      border: 0;
    }

    input, textarea {
      font-size: 16px;
      color: black;
    }
    input {
      height: 45px;

      &[type="submit"] {
        font-size: 20px;
        color: white;
        background-color: $purple-light;
        transition: background-color .5s ease-in-out;

        &:hover {
          background-color: $purple;
        }
      }
    }
  }
}



