/** Social icons. **/
@function medium($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="31" height="24"><path fill="' + $fillColour + '" d="M3.813 5.049a.97.97 0 0 0-.375-.972L.598.713V.189h8.748l6.729 14.804L22.055.189h8.301v.524L27.963 3.03c-.226.15-.3.449-.3.672v16.973c-.073.224.075.522.3.672l2.317 2.317v.524H18.541v-.524l2.469-2.392c.224-.225.224-.299.224-.673V6.843L14.43 24.04h-.897L5.606 6.843v11.514c-.075.449.075.972.449 1.346l3.14 3.814v.522H.224v-.522l3.141-3.814c.374-.374.523-.822.374-1.346V5.049h.074z"/></svg>');
}

@function linkedin($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="' + $fillColour + '" d="M23.898 15.202V24h-5.1v-8.209c0-2.063-.736-3.47-2.584-3.47-1.41 0-2.248.949-2.617 1.865-.135.327-.17.784-.17 1.244V24H8.326s.069-13.904 0-15.345h5.102v2.175l-.033.049h.033v-.049c.678-1.044 1.889-2.536 4.6-2.536 3.355.001 5.87 2.194 5.87 6.908zM2.989 1.259c-1.746 0-2.888 1.145-2.888 2.65 0 1.472 1.108 2.653 2.819 2.653h.035c1.779 0 2.886-1.18 2.886-2.653-.033-1.505-1.107-2.65-2.852-2.65zM.404 24h5.101V8.655H.404V24z"/></svg>');
}

@function facebook($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="' + $fillColour + '" d="M9.235 4.94v3.262H6.844v3.99h2.391V24.05h4.912V12.192h3.295s.309-1.913.457-4.004h-3.734V5.459c0-.407.535-.956 1.066-.956h2.674V.35h-3.639C9.113.349 9.235 4.344 9.235 4.94z"/></svg>');
}


/** Buttons */
@function close($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="50" height="50"><path fill="' + $fillColour + '" d="M21.734 19.64l-2.097 2.094a.983.983 0 0 1-1.395 0L13 16.496l-5.238 5.238a.988.988 0 0 1-1.399 0l-2.097-2.093a.988.988 0 0 1 0-1.399L9.504 13 4.266 7.762a.995.995 0 0 1 0-1.399l2.097-2.097a.988.988 0 0 1 1.399 0L13 9.508l5.242-5.242a.983.983 0 0 1 1.395 0l2.097 2.093a.996.996 0 0 1 .004 1.403L16.496 13l5.238 5.242a.988.988 0 0 1 0 1.399z"/></svg>');
}

/** Arrows **/
@function arrow-down($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="40" height="40"><path fill="' + $fillColour + '" d="M35.275 36.046l15.718 15.718L66.917 35.84a3 3 0 0 1 4.24 4.24l.003.003-18 18A2.994 2.994 0 0 1 51 59h-.014a2.99 2.99 0 0 1-2.16-.918l-18-18 .008-.007a3 3 0 1 1 4.44-4.03z" fill-rule="evenodd" clip-rule="evenodd"/></svg>');
}

@function arrow-right($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill="' + $fillColour + '" d="M7.5 4.5L6.44 5.56 9.88 9l-3.44 3.44L7.5 13.5 12 9z"/></svg>');
}

@function arrow-flow($fillColour) {
  @return svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 37"><path d="M5.708 0h104.086a6 6 0 0 1 4.779 2.372l9.102 11.987a6 6 0 0 1 .022 7.228l-9.125 12.169a6 6 0 0 1-4.8 2.4H5.708a5.707 5.707 0 0 1-4.634-9.039l3.981-5.536a6 6 0 0 0 0-7.006L1.074 9.04A5.707 5.707 0 0 1 5.708 0z" fill="' + $fillColour + '" fill-rule="evenodd"/></svg>');
}
